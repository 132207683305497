import type { Editor } from 'tldraw'
import { Box, Vec, pointInPolygon } from 'tldraw'
import type { Box as BBox } from '../../../generated/server/models/Box.ts'
import type { PieceCircleShape } from '../../annot/piece/circle/shape'
import { isPieceCircleShape } from '../../annot/piece/circle/shape'
import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AttrValue } from '../../attr/state/context.ts'
import { type AIModelOutputFireProtectionPipes, type AIOutputFireProtectionSegment, type AIOutputLabeledBox, server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { getHeadStrict } from '../../util/web/array.ts'
import { groupBy } from '../../util/web/object'
import type { PredictAreaShape } from '../area/shape.ts'
import { mergePredictFetchOutput } from '../fetch/merge'
import type { PredictFetch, PredictFetchInput, PredictFetchOutput } from '../fetch/type'
import { parsePredictPipelines } from '../parse/pipeline'
import type { PredictPolygonAreaShape } from '../polygon-area/shape.ts'
import { linePointsToArray } from '../polygon-area/util.ts'
import { getPredictFireHeads } from './fire-head.ts'
import type { PredictModelBase } from './type'

function addFirePipe(props: {
  output: PredictFetchOutput
}): PredictFetchOutput {
  const { output: prevOutput } = props

  const group = getHeadStrict(prevOutput.shapes).meta.group

  const prevAttr = prevOutput.attrs[group]
  if (prevAttr === undefined)
    throw new Error(`attr is missing for ${group}`)
  const nextAttr: AttrValue = { ...prevAttr, firePipe: 'Sub' }

  const nextAttrs = { ...prevOutput.attrs, [group]: nextAttr }
  const nextOutput = { ...prevOutput, attrs: nextAttrs }
  return nextOutput
}

export function validateWaterSource(
  editor: Editor,
  waterSources: PieceCircleShape[],
  areas: PredictAreaShape[],
  polygonAreas: PredictPolygonAreaShape[],
): boolean {
  if (waterSources.length === 0 || waterSources.length < areas.length + polygonAreas.length)
    return false

  // Validate all rectangle areas
  const areRectAreasValid = areas.every(area => isWSContainedArea(editor, waterSources, area))
  // Validate all polygon areas
  const arePolygonAreasValid = polygonAreas.every(polygonArea => isWSInsidePolygon(editor, waterSources, polygonArea))
  // Return true only if all rectangle areas and polygon areas are valid
  return areRectAreasValid && arePolygonAreasValid
}

function isWSContainedArea(
  editor: Editor,
  waterSources: PieceCircleShape[],
  area: PredictAreaShape,
): boolean {
  const containerBounds = editor.getShapePageBounds(area)
  if (!containerBounds)
    throw new Error('No bounds found for area')

  return waterSources.some((waterSource) => {
    const waterSourceBounds = editor.getShapePageBounds(waterSource)
    if (!waterSourceBounds)
      throw new Error('No bounds found for water source')

    return containerBounds.contains(waterSourceBounds)
  })
}

function isWSInsidePolygon(
  editor: Editor,
  waterSources: PieceCircleShape[],
  polygonArea: PredictPolygonAreaShape,
): boolean {
  const containerBounds = editor.getShapePageBounds(polygonArea)
  if (!containerBounds)
    throw new Error('No bounds found for polygon area')

  // Transform polygon points based on area position
  const transformedPoints = linePointsToArray(polygonArea)
    .map(Vec.From)
    .map(point => ({ x: point.x + polygonArea.x, y: point.y + polygonArea.y }))

  // Check if any waterSource center is inside the polygon
  return waterSources.some((waterSource) => {
    const center = new Box(
      waterSource.x,
      waterSource.y,
      waterSource.props.w,
      waterSource.props.h,
    ).center
    return pointInPolygon(center, transformedPoints)
  })
}

export function getWaterSources(input: PredictFetchInput): BBox[] {
  return input.shapes
    .filter(isPieceCircleShape)
    .map((shape): BBox => {
      const global = new Box(shape.x, shape.y, shape.props.w, shape.props.h)
      const local = input.area.localise.box(global)
      const { x, y, w, h } = local
      return { x, y, width: w, height: h }
    })
}

function parseDiameter(segment: AIOutputFireProtectionSegment): AIOutputLabeledBox | null {
  if (segment.diameter === -1)
    return null

  const { p1, p2 } = segment

  // Most of the box is unnecessary for diameter,
  // but we need its shape to use parse pipeline
  return {
    box: {
      height: p2.y - p1.y,
      width: p2.x - p1.x,
      x: p1.x,
      y: p1.y,
    },
    label: {
      equipment_attributes: {},
      equipment_attributes_extra: [],
      equipment_class: '',
      equipment_type: '',
      // Only need this so far
      equipment_diameter: String(segment.diameter),
    },
  }
}

export function parsePredictFirePipes(props: {
  input: PredictFetchInput
  raw: AIModelOutputFireProtectionPipes
}): PredictFetchOutput {
  const { input, raw } = props

  // Due to our bad design in FE, there are actually 2 types of updates here:
  // - "segments" is the real payload from AI that we should render on the canvas.
  // - "aiPredictionRemaining" is the meta from BE that we should update the FE's data.
  //
  // Before the introduction of the meta info,
  // our code (below) works by eagerly skipping updates
  // if the payload is considered "empty" (no segments).
  //
  // This breaks the meta update, because when segments are empty,
  // there is no item (in "prev" or "next") to attach the meta to.
  // See also: https://github.com/H2-Corporation/hs-hais-editor/pull/205
  //
  // The temporary fix here is to return the meta manually
  // if the segments are empty.
  //
  // A better fix would be _not_ relying on the meta info here.
  // Instead, we should always invalidate the meta itself,
  // which triggers a separated fetch to update the meta.
  if (raw.segments === undefined || raw.segments.length === 0) {
    return {
      attrs: {},
      shapes: [],
      aiPredictionRemaining: raw.aiPredictionRemaining,
    }
  }

  const prev = Object.values(groupBy({
    array: raw.segments ?? [],
    // Heads up: "fire-pipe" returns "-1" for all diameters.
    // Only "fire-pipe-diameter" returns the actual diameter.
    getKey: segment => `${segment.diameter}-${JSON.stringify(segment.metadata)}`,
  }))

  const next = prev.flatMap((segments) => {
    const metadata = segments.at(0)?.metadata
    const pipelines1 = parsePredictPipelines({
      pipelines: [{
        diameters: segments.map(parseDiameter)
          .filter((d): d is AIOutputLabeledBox => d !== null),
        lines: segments ?? [],
        type: null,
        vertical_segments: null,
        metadata: metadata ? [{ key: 'fire-pipe', value: JSON.stringify(metadata) }] : [],
      }],
      equipFallback: ATTR_EQUIP_VALUES.FIRE_PROTECTION_PIPE,
      transform: input.area.globalise,
      aiPredictionRemaining: raw.aiPredictionRemaining,
    })
    const pipelines2 = addFirePipe({ output: pipelines1 })
    return pipelines2
  })

  return mergePredictFetchOutput(next)
}

const fetch: PredictFetch = async (input) => {
  const output: PredictFetchOutput = {
    attrs: {},
    shapes: [],
    aiPredictionRemaining: Number.POSITIVE_INFINITY,
  }

  const heads = getPredictFireHeads(input)
  const waterSources = getWaterSources(input)

  if (heads.length === 0 || waterSources.length === 0)
    return output

  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const raw = await server.predictFireProtectionPipesByAi({
    pageID: input.page,
    sessionID: input.session,
    image: input.area.blob,
    sprinklerHeads: JSON.stringify(heads),
    cropping: JSON.stringify(cropArea),
    waterSources: JSON.stringify(waterSources),
  })

  return parsePredictFirePipes({ input, raw })
}

export const PredictModelFirePipe = {
  value: 'fire-pipe',
  label: t('predict.model.fire-pipe'),
  system: 'fire',
  sample: false,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: true,
  aiParams: false,
} as const satisfies PredictModelBase
