import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AIModelOutputSanitary } from '../../util/data/server'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import type { PredictFetch } from '../fetch/type'
import { parsePredictPipelines, parsePredictPolyline } from '../parse/pipeline'
import type { PredictModelBase } from './type'

const fetch: PredictFetch = async (input) => {
  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const raw = await server.predictEquipmentsByAi({
    image: input.area.blob,
    model: 'Sanitary',
    pageID: input.page,
    sessionID: input.session,
    cropping: JSON.stringify(cropArea),
  })
  const output = raw.output as AIModelOutputSanitary

  const pipelines = parsePredictPipelines({
    pipelines: output.pipelines.map(pipeline => ({
      diameters: pipeline.diameters,
      lines: pipeline.polylines.flatMap(parsePredictPolyline),
      type: pipeline.type,
      vertical_segments: pipeline.vertical_segments,
      metadata: [],
    })),
    equipFallback: ATTR_EQUIP_VALUES.SANITARY_PIPE,
    transform: input.area.globalise,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  return pipelines
}

export const PredictModelSanitary = {
  value: 'sanitary',
  label: t('predict.model.sanitary'),
  system: 'plumbing',
  sample: false,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
  aiParams: false,
} as const satisfies PredictModelBase
