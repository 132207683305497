import type { TLDefaultColorStyle, TLShapeId, VecLike } from 'tldraw'
import { Vec, createShapeId, getIndices } from 'tldraw'
import type { Interactive, KeyValueString } from '../../../../generated/server'
import { getStrict } from '../../../util/web/primitive'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { SegmentFlatPartial, SegmentFlatShape } from './shape'

export const SEGMENT_FLAT_INDICES = (() => {
  const indices = getIndices(2)
  return {
    start: getStrict(indices.at(0)),
    end: getStrict(indices.at(1)),
  } as const
})()

export function createSegmentFlatShape(props: {
  start: VecLike
  end: VecLike
  //
  id: TLShapeId | null
  group: string | null
  color: TLDefaultColorStyle | null
  interactive: Interactive | null
  zoneID: string
  metadata: KeyValueString[]
}): SegmentFlatPartial {
  const { start: absStart, end: absEnd, group, id, color, interactive, zoneID, metadata } = props

  const relEnd = Vec.From(absEnd).sub(absStart)
  const { end: endID, start: startID } = SEGMENT_FLAT_INDICES

  const points: SegmentFlatShape['props']['points'] = {
    // We follow tldraw's pattern here that the "start" handle starts at 0, 0.
    // (the actual "start" point is the "line.x" and ".y".)
    [startID]: { id: startID, index: startID, x: 0, y: 0 },
    [endID]: { id: endID, index: endID, x: relEnd.x, y: relEnd.y },
  }

  const flat: SegmentFlatPartial = {
    ...(ANNOT_SHAPE_BASE),
    id: id ?? createShapeId(),
    type: 'line',
    x: absStart.x,
    y: absStart.y,
    props: {
      dash: 'solid',
      size: 's',
      color: color ?? randomAnnotShapeColor(),
      points,
    },
    meta: {
      type: 'annot',
      group: group ?? crypto.randomUUID(),
      interactive,
      annot: 'segment',
      segment: 'flat',
      zoneID,
      metadata,
    },
  }

  return flat
}
