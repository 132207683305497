import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import type { AIModelOutputDrainReibai } from '../../util/data/server'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { mergePredictFetchOutput } from '../fetch/merge'
import type { PredictFetch } from '../fetch/type'
import { parsePredictExtensions } from '../parse/extension'
import { parsePredictPipelines } from '../parse/pipeline'
import type { PredictModelBase } from './type'

const fetch: PredictFetch = async (input) => {
  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const raw = await server.predictEquipmentsByAi({
    image: input.area.blob,
    model: 'DrainReibai',
    pageID: input.page,
    sessionID: input.session,
    cropping: JSON.stringify(cropArea),
  })
  const output = raw.output as AIModelOutputDrainReibai

  const pipelines = parsePredictPipelines({
    pipelines: output.pipelines.map(pipeline => ({
      diameters: pipeline.diameters,
      lines: pipeline.lines,
      type: pipeline.type,
      vertical_segments: pipeline.vertical_segments,
      metadata: [],
    })),
    equipFallback: ATTR_EQUIP_VALUES.DRAIN_PIPE,
    transform: input.area.globalise,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  const extensions = parsePredictExtensions({
    extensions: output.length_extension,
    transform: input.area.globalise,
    fallbackEquip: ATTR_EQUIP_VALUES.REFRIGERANT_PIPE,
    aiPredictionRemaining: raw.aiPredictionRemaining,
  })

  return mergePredictFetchOutput([pipelines, extensions])
}

export const PredictModelDrain = {
  value: 'drain',
  label: t('predict.model.drain'),
  system: 'mechanical',
  sample: false,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
  aiParams: false,
} as const satisfies PredictModelBase
