import type { TLCancelEvent, TLEnterEventHandler, TLEventHandlers, TLHandle, TLInterruptEvent, TLLineShape, TLPointerEvent, TLShape, TLShapeId, TLStateNodeConstructor } from 'tldraw'
import { StateNode, Vec, createShapeId, structuredClone } from 'tldraw'
import { isEditorOrphanedShape } from '../../../editor/shape/base'
import { getLineShapeEdgeAbsolute, isLineShape } from '../../../editor/shape/line'
import { getOnlyStrict, last } from '../../../util/web/array'
import { isAnnotShape } from '../../shape/shape'
import { createSegmentExtensionShape } from '../extension/create'
import { isSegmentShape } from '../shape'
import { createSegmentVerticalPipeShape } from '../vertical/pipe/create'
import { createSegmentFlatShape } from './create'
import { isSegmentFlatShape } from './shape'

type onEnterLineToolInfo = {
  from: 'line'
  onInteractionEnd: string
}

type onEnterGeoToolInfo = {
  direction: 'up' | 'down'
  extension: boolean
  onInteractionEnd: string
}

class Create extends StateNode {
  static override id = 'create'

  from = 'line'
  onInteractionEnd = 'line'

  markId = ''

  shape = {} as TLShape

  override onEnter?: TLEnterEventHandler | undefined = (info: onEnterLineToolInfo) => {
    const { inputs } = this.editor
    this.from = info.from ?? 'line'

    this.onInteractionEnd = info.onInteractionEnd ?? 'line'
    const { currentPagePoint } = inputs
    const id = createShapeId()

    const prev = last(this.editor.getSelectedShapes()
      .filter(isSegmentShape)) ?? null

    this.editor.createShapes([{
      id,
      type: info.from ?? 'line',
      x: currentPagePoint.x,
      y: currentPagePoint.y,
    }])

    if (prev) {
      const orphans = this.editor
        .getCurrentPageShapes()
        .filter(isEditorOrphanedShape)
        .filter(isLineShape)

      const line = getOnlyStrict(orphans)
      const edge = getLineShapeEdgeAbsolute(line)

      const segment = createSegmentFlatShape({
        start: edge.start,
        end: edge.end,
        //
        id: line.id,
        group: prev.meta.group as string ?? null,
        color: prev.props.color ?? null,
        interactive: 'ByManual',
        zoneID: '',
        metadata: [],
      })
      this.editor.createShape(segment)
    }

    this.editor.select(id)
    this.shape = this.editor.getShape(id)!
  }

  override onPointerMove: TLEventHandlers['onPointerMove'] = () => {
    if (!this.shape)
      return

    this.parent.transition('moving', {
      shapeId: this.shape.id,
      from: this.from,
      onInteractionEnd: this.onInteractionEnd,
    })
  }

  override onCancel: TLEventHandlers['onCancel'] = () => {
    this.cancel()
  }

  override onComplete: TLEventHandlers['onComplete'] = () => {
    this.complete()
  }

  override onInterrupt: TLInterruptEvent = () => {
    this.editor.setCurrentTool(this.onInteractionEnd)
    this.editor.deselect(...this.editor.getSelectedShapeIds())
    this.editor.snaps.clearIndicators()
  }

  complete() {
    this.editor.setCurrentTool(this.onInteractionEnd)
    this.editor.deselect(...this.editor.getSelectedShapeIds())
    this.editor.snaps.clearIndicators()
  }

  cancel() {
    this.editor.setCurrentTool(this.onInteractionEnd)
    this.editor.deselect(...this.editor.getSelectedShapeIds())
    this.editor.snaps.clearIndicators()
  }
}

class Moving extends StateNode {
  static override id = 'moving'
  initialPagePoint: Vec | undefined
  initialPageRotation: number | undefined
  shape = {} as TLLineShape
  handle = {} as TLHandle
  from = 'line'
  onInteractionEnd = 'line'

  override onEnter?: TLEnterEventHandler | undefined = ({ shapeId, from, onInteractionEnd }: { shapeId: TLShapeId, from: string, onInteractionEnd: string }) => {
    const shape = this.editor.getShape<TLLineShape>(shapeId)
    if (!shape)
      throw new Error('No shape found')

    const handles = this.editor.getShapeHandles(shape)
    if (!handles)
      throw new Error('No handle found')

    this.handle = structuredClone(handles.at(-1)!)
    this.shape = shape
    this.initialPagePoint = this.editor.inputs.originPagePoint.clone()
    this.initialPageRotation = this.editor.getShapePageTransform(shape).rotation()
    this.from = from ?? 'line'
    this.onInteractionEnd = onInteractionEnd
  }

  override onPointerMove?: TLPointerEvent | undefined = () => {
    const { currentPagePoint } = this.editor.inputs

    const point = currentPagePoint.clone().sub(this.initialPagePoint!).rot(-this.initialPageRotation!)

    const points = structuredClone(this.shape.props.points)
    points[this.handle.index] = {
      id: this.handle.id,
      index: this.handle.index,
      x: point.x,
      y: point.y,
    }
    this.editor.updateShapes(
      [
        { ...this.shape, props: { points } },
      ],
    )
  }

  override onPointerDown?: TLPointerEvent | undefined = () => {
    this.parent.transition('create', {
      from: this.from,
      onInteractionEnd: this.onInteractionEnd,
    })
  }

  override onCancel: TLCancelEvent | undefined = () => {
    const latestShape = last(this.editor.getSelectedShapes().filter(isAnnotShape).filter(isSegmentFlatShape))
    if (latestShape)
      this.editor.deleteShape(latestShape)

    this.editor.deselect(...this.editor.getSelectedShapeIds())
    this.editor.mark()
    this.editor.setCurrentTool(this.onInteractionEnd)
  }

  override onRightClick: TLEventHandlers['onRightClick'] = () => {
    const latestShape = last(this.editor.getSelectedShapes().filter(isAnnotShape).filter(isSegmentFlatShape))
    if (latestShape)
      this.editor.deleteShape(latestShape)

    this.editor.deselect(...this.editor.getSelectedShapeIds())
    this.editor.mark()
    this.editor.setCurrentTool(this.onInteractionEnd)
  }
}

class LineToGeo extends StateNode {
  static override id = 'line-to-geo'

  markId = ''

  shape = {} as TLShape

  onInteractionEnd = 'line'

  override onEnter?: TLEnterEventHandler | undefined = ({ info, shape }: { info: onEnterGeoToolInfo, shape: TLLineShape }) => {
    if (!shape)
      throw new Error('No shape found')

    this.onInteractionEnd = info.onInteractionEnd ?? 'line'

    if (isAnnotShape(shape)) {
      if (info.extension === false) {
        const segment = createSegmentVerticalPipeShape({
          center: new Vec(shape.x, shape.y),
          direction: info.direction,
          id: createShapeId(),
          color: shape.props.color ?? null,
          group: shape.meta.group ?? null,
          mm: null,
          interactive: 'ByManual',
          zoneID: shape.meta.zoneID,
        })
        this.editor.createShape(segment)
      }
      else {
        const extension = createSegmentExtensionShape({
          center: new Vec(shape.x, shape.y),
          mm: null,
          //
          color: shape.props.color ?? null,
          group: shape.meta.group ?? null,
          id: createShapeId(),
          interactive: 'ByManual',
          zoneID: shape.meta.zoneID,
        })
        this.editor.createShape(extension)
      }

      const prev = last(this.editor.getSelectedShapes()
        .filter(isSegmentShape)) ?? null

      if (prev)
        this.editor.deleteShape(prev)

      this.shape = shape
      this.editor.select(shape.id)
    }
  }

  override onPointerMove: TLEventHandlers['onPointerMove'] = () => {
    if (!this.shape)
      return

    this.parent.transition('geo-to-line', {
      shape: this.shape,
      onInteractionEnd: this.onInteractionEnd,
    })
  }
}

class GeoToLine extends StateNode {
  static override id = 'geo-to-line'

  markId = ''

  shape = {} as TLShape

  onInteractionEnd = 'line'

  override onEnter?: TLEnterEventHandler | undefined = ({ shape, onInteractionEnd }: { shape: TLLineShape, onInteractionEnd: string }) => {
    if (!shape)
      throw new Error('No shape found')

    this.onInteractionEnd = onInteractionEnd ?? 'line'
    const edge = getLineShapeEdgeAbsolute(shape)

    const segment = createSegmentFlatShape({
      start: edge.start,
      end: edge.end,
      //
      id: shape.id,
      group: shape.meta.group as string ?? null,
      color: shape.props.color ?? null,
      interactive: 'ByManual',
      zoneID: shape.meta.zoneID as string ?? '',
      metadata: [],
    })

    this.editor.createShape(segment)
    this.editor.select(shape.id)

    this.shape = this.editor.getShape(shape.id)!
  }

  override onPointerMove: TLEventHandlers['onPointerMove'] = () => {
    if (!this.shape)
      return

    this.parent.transition('moving', {
      shapeId: this.shape.id,
      from: 'line',
      onInteractionEnd: this.onInteractionEnd,
    })
  }
}

export class BaseLineTool extends StateNode {
  static override id = 'line-select'
  static override initial = 'create'
  static override children?: (() => TLStateNodeConstructor[]) | undefined = () => [Create, Moving, GeoToLine, LineToGeo]
}
